.c1,
.c1 a {
	color: #c69b6d !important;
}

.c1-alt,
.c1-alt a {
	color: #c69b6d !important;
}

.has-content .c1-alt,
.has-content.c1-alt {
	color: #997854 !important;
}

.c2,
.c2 a {
	color: #f48cba !important;
}

.c2-alt,
.c2-alt a {
	color: #f48cba !important;
}

.has-content .c2-alt,
.has-content.c2-alt {
	color: #cc749c !important;
}

.c3,
.c3 a {
	color: #aad372 !important;
}

.c3-alt,
.c3-alt a {
	color: #aad372 !important;
}

.has-content .c3-alt,
.has-content.c3-alt {
	color: #7c9953 !important;
}

.c4,
.c4 a {
	color: #fff468 !important;
}

.c4-alt,
.c4-alt a {
	color: #fff468 !important;
}

.has-content .c4-alt,
.has-content.c4-alt {
	color: #99933f !important;
}

.c5,
.c5 a {
	color: #fff !important;
}

.c5-alt,
.c5-alt a {
	color: #fff !important;
}

.has-content .c5-alt,
.has-content.c5-alt {
	color: gray !important;
}

.c6,
.c6 a {
	color: #c41e3b !important;
}

.c6-alt,
.c6-alt a {
	color: #f46 !important;
}

.has-content .c6-alt,
.has-content.c6-alt {
	color: #c41e3b !important;
}

.c7,
.c7 a {
	color: #2359ff !important;
}

.c7-alt,
.c7-alt a {
	color: #36f !important;
}

.has-content .c7-alt,
.has-content.c7-alt {
	color: #2359ff !important;
}

.c8,
.c8 a {
	color: #68ccef !important;
}

.c8-alt,
.c8-alt a {
	color: #68ccef !important;
}

.has-content .c8-alt,
.has-content.c8-alt {
	color: #4f98b3 !important;
}

.c9,
.c9 a {
	color: #9382c9 !important;
}

.c9-alt,
.c9-alt a {
	color: #9382c9 !important;
}

.has-content .c9-alt,
.has-content.c9-alt {
	color: #9382c9 !important;
}

.c10,
.c10 a {
	color: #008467 !important;
}

.c10-alt,
.c10-alt a {
	color: #008467 !important;
}

.has-content .c10-alt,
.has-content.c10-alt {
	color: #008467 !important;
}

.c11,
.c11 a {
	color: #ff7c0a !important;
}

.c11-alt,
.c11-alt a {
	color: #ff7c0a !important;
}

.has-content .c11-alt,
.has-content.c11-alt {
	color: #ff7c0a !important;
}

.c12,
.c12 a {
	color: #a330c9 !important;
}

.c12-alt,
.c12-alt a {
	color: #a330c9 !important;
}

.has-content .c12-alt,
.has-content.c12-alt {
	color: #a330c9 !important;
}

.wowhead-tooltip .c1,
.wowhead-tooltip .c1 a {
	color: #c69b6d !important;
}

.wowhead-tooltip .c1-alt,
.wowhead-tooltip .c1-alt a {
	color: #c69b6d !important;
}

.wowhead-tooltip .c2,
.wowhead-tooltip .c2 a {
	color: #f48cba !important;
}

.wowhead-tooltip .c2-alt,
.wowhead-tooltip .c2-alt a {
	color: #f48cba !important;
}

.wowhead-tooltip .c3,
.wowhead-tooltip .c3 a {
	color: #aad372 !important;
}

.wowhead-tooltip .c3-alt,
.wowhead-tooltip .c3-alt a {
	color: #aad372 !important;
}

.wowhead-tooltip .c4,
.wowhead-tooltip .c4 a {
	color: #fff468 !important;
}

.wowhead-tooltip .c4-alt,
.wowhead-tooltip .c4-alt a {
	color: #fff468 !important;
}

.wowhead-tooltip .c5,
.wowhead-tooltip .c5 a {
	color: #fff !important;
}

.wowhead-tooltip .c5-alt,
.wowhead-tooltip .c5-alt a {
	color: #fff !important;
}

.wowhead-tooltip .c6,
.wowhead-tooltip .c6 a {
	color: #c41e3b !important;
}

.wowhead-tooltip .c6-alt,
.wowhead-tooltip .c6-alt a {
	color: #c41e3b !important;
}

.wowhead-tooltip .c7,
.wowhead-tooltip .c7 a {
	color: #2359ff !important;
}

.wowhead-tooltip .c7-alt,
.wowhead-tooltip .c7-alt a {
	color: #2359ff !important;
}

.wowhead-tooltip .c8,
.wowhead-tooltip .c8 a {
	color: #68ccef !important;
}

.wowhead-tooltip .c8-alt,
.wowhead-tooltip .c8-alt a {
	color: #68ccef !important;
}

.wowhead-tooltip .c9,
.wowhead-tooltip .c9 a {
	color: #9382c9 !important;
}

.wowhead-tooltip .c9-alt,
.wowhead-tooltip .c9-alt a {
	color: #9382c9 !important;
}

.wowhead-tooltip .c10,
.wowhead-tooltip .c10 a {
	color: #008467 !important;
}

.wowhead-tooltip .c10-alt,
.wowhead-tooltip .c10-alt a {
	color: #008467 !important;
}

.wowhead-tooltip .c11,
.wowhead-tooltip .c11 a {
	color: #ff7c0a !important;
}

.wowhead-tooltip .c11-alt,
.wowhead-tooltip .c11-alt a {
	color: #ff7c0a !important;
}

.wowhead-tooltip .c12,
.wowhead-tooltip .c12 a {
	color: #a330c9 !important;
}

.wowhead-tooltip .c12-alt,
.wowhead-tooltip .c12-alt a {
	color: #a330c9 !important;
}
