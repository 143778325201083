.link-card {
	color: var(--white) !important;

	&:hover {
		color: inherit;
		text-decoration: none;
	}

	.card {
		transition: background-color 100ms linear;

		&:hover {
			background-color: lighten(#4e5d6c, 10%);
		}
	}
}
